import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { LanguageService } from '@shared/services/language.service';
export enum customDateFormat  {
  short= 'L LT',
  medium= 'L LTS',
  shortTime= 'LT',
  mediumTime = 'LTS',
  shortDate ='L'
}
@Injectable({
  providedIn: 'root',
})
export class DateService {
  private readonly timeZone: string;

  constructor(private languageService: LanguageService) {
    this.timeZone = moment.tz.guess();
    console.log("test timzeone ", this.timeZone)

  }

  public moment(date?: moment.MomentInput): moment.Moment {
    moment.locale(this.languageService.currentLanguage);

    return date ? moment(date).tz(this.timeZone) : moment().tz(this.timeZone);
  }


  getBrowserTimezone(): string {
    return this.timeZone;
  }
}
