import { Pipe, PipeTransform } from '@angular/core';
import { customDateFormat, DateService } from '@shared/services/date.service';

@Pipe({
  name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: string | Date, format: string = 'L '): string {
    const momentFormat = customDateFormat[format] || format;

    let momentValue;
    if (typeof value === 'string' && /^\d+$/.test(value)) {
      momentValue = this.dateService.moment(Number(value)); // Convert string to number
    } else {
      momentValue = this.dateService.moment(value);
    }

    if (!momentValue.isValid()) {
      console.error('Invalid Date:', value);
      return 'Invalid date';
    }

    return momentValue.format(momentFormat);
  }

}
