import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApolloLink, from, InMemoryCache, NextLink, Operation } from '@apollo/client/core';
import { environment } from '../../environments/environment';
import { LanguageService } from '../shared/services/language.service';
import { TokenSessionService } from '../shared/services/token.session.service';
import { onError } from '@apollo/client/link/error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FeaturesEnum } from '@shared/models/features.enum';
import { first } from 'rxjs/operators';
import { FeaturesFlagService } from '@shared/services/features-flag.service';

@NgModule({
  exports: [ ApolloModule]
})
export class GraphQLModule {
  constructor(
    apollo: Apollo,
    private httpLink: HttpLink,
    languageService: LanguageService,
    private tokenSessionService: TokenSessionService,
    private snackbar: MatSnackBar,
    private featuresFlagsService: FeaturesFlagService
  ) {
    // Middleware for adding authentication tokens to requests
    const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
      const token = this.tokenSessionService.getToken();
      if (token) {
        operation.setContext({
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        });
      }
      return forward(operation);
    });

    // Error handling link
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      const networkErr = networkError as any;

      if (networkError) {
        // Handle specific network errors
        if (networkErr.status === 500 || networkErr.status === 400) {
          console.error('Network Error:', networkError);

          // Show error notification if the feature flag is enabled
          this.featuresFlagsService.getFeatureFlagByName(FeaturesEnum.ERROR_NOTIFICATION)
            .pipe(first())
            .subscribe((feature) => {
              if (feature?.active) {
                const errorMessage = networkErr.error?.errors?.[0]?.message2 || networkErr.error?.errors?.[0]?.message || 'An error occurred';
                this.snackbar.open(errorMessage, 'Ok', { duration: 6000 });
              }
            });
        }

        // Logout if the token is expired
        this.tokenSessionService.tokenExpiredLogout();
      }
    });

    // Server link for actual GraphQL API
    const serverLink = this.httpLink.create({
      uri: `${document.location.protocol}//${document.location.hostname}${environment.apiUrl.replace('{{lang}}', languageService.currentLanguage)}`
    });

    // Combine links
    const apolloLink = from([
      errorLink,             // Handle errors without retries
      authMiddleware,        // Attach authentication headers
      serverLink             // Send the request to the server
    ]);

    // Default client
    apollo.create({
      link: apolloLink,
      cache: new InMemoryCache()
    });

    // Named client for mock API
    apollo.createNamed('mockApiClient', {
      link: this.httpLink.create({
        uri: `${document.location.protocol}//${document.location.hostname}${environment.mockApiUrl}`
      }),
      cache: new InMemoryCache()
    });
  }
}
