import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { GraphqlService } from '../../graphql/graphql.service';
import { Alarm } from '../../shared/models/alarm';
import { Device } from '../../shared/models/device';
import { DeviceType } from '../../shared/models/device-type.enum';
import { Equipment } from '../../shared/models/equipment';
import { Event } from '../../shared/models/event';
import { EventType } from '../../shared/models/event-type';
import { PagedResult } from '../../shared/services/paged-result';
import { EventTypesResult } from '../services/event-types-result';
import { EventsResult } from '../services/events-result';
import { AlarmsListSearchCriteria } from './alarms-list-search-criteria.interface';
import { EquipmentAlarmsChange } from './equipment-alarms-change.interface';
import { LogsListSearchCriteria } from './logs-table-search-criteria.interface';
import { UtilsService } from '@shared/services/utils.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { LanguageService } from '@shared/services/language.service';
import { TokenSessionService } from '@shared/services/token.session.service';
import { DateService } from '@shared/services/date.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentDetailsService {
  public equipmentAlarmsChanged: BehaviorSubject<EquipmentAlarmsChange> = new BehaviorSubject<EquipmentAlarmsChange>(null);

  constructor(private graphqlService: GraphqlService, private httpClient: HttpClient, private languageService: LanguageService, private tokenSessionService: TokenSessionService, private dateService: DateService) {
  }

  public getEquipment(id: string): Observable<any> {
    return this.graphqlService.query(this.getEquipmentQuery(id), {}, false).pipe(
      map(result => {
        console.log('EquipmentDetailsService getEquipment result', result);
        let equipment = null;
        if (result.data.getEquipmentById) {
          equipment = new Equipment(result.data.getEquipmentById);
        }

        return equipment;
      })
    );
  }

  public getEquipmentsInCustomerLocationForBreadCrumbsComponent(customerLocationIds: string): Observable<any> {
    if (!customerLocationIds) {
      return new Observable(subscriber => {
        subscriber.next([]);
      });
    }

    return this.graphqlService
      .query(this.getEquipmentsInCustomerLocationQuery(true), {
        customerLocationIds: [customerLocationIds],
        page: 1,
        perPage: 3000
      }, false)
      .pipe(
        map(result => {
          console.log('EquipmentDetailsService getEquipmentsInCustomerLocationForBreadCrumbsComponent result', result);

          return result.data.getEquipmentsInCustomerLocation.itemsPage.map((el: any) => {
            let icon = 'assets/images/eq_status_normal_green.svg';
            let tooltip = 'website.alarms.NORMAL_STATE';
            const status = el.unit_flags_and_alarms?.simultaneous_unit_status || [];
            if (status.includes('fluid_alarm') || status.includes('statistical_alarm')) {
              icon = 'assets/images/alarm_level_red.svg';
              tooltip = 'website.alarms.EVT_FLUID';
            } else if (status.includes('technical_alarm') || status.includes('disconnected')) {
              icon = 'assets/images/eq_status_technical_red.svg';
              tooltip = 'website.alarms.EVT_TECHNICAL';
            }
            return {
              tooltip,
              icon,
              displayValue: el.name,
              value: el.id
            };
          });
        })
      );
  }

  public getEquipmentsInCustomerLocation(customerLocationIds: string, search?: string): Promise<PagedResult<Equipment>> {
    if (!customerLocationIds) {
      return Promise.resolve(new PagedResult<Equipment>([]));
    }

    return this.graphqlService
      .query(this.getEquipmentsInCustomerLocationQuery(true), {
        customerLocationIds: [customerLocationIds],
        search,
        page: 1,
        perPage: 3000
      }, false)
      .pipe(
        take(1),
        map(result => {
          const equipmentPageResult: PagedResult<Equipment> = new PagedResult<Equipment>();
          equipmentPageResult.itemsPage = result.data.getEquipmentsInCustomerLocation.itemsPage.map((equipment: any) => new Equipment(equipment));
          return equipmentPageResult;
        })
      ).toPromise();
  }

  public deactivateAlarmsForEquipment(equipmentId: string): Promise<any> {
    return this.graphqlService.mutate(this.deactivateAlarmsForEquipmentMutation(), { equipmentId }).toPromise();
  }

  public async getEquipmentActiveAlarms(equipmentId: string, criteria: AlarmsListSearchCriteria): Promise<PagedResult<Alarm>> {
    const gqlParams: any = { equipmentId };

    if (criteria.filters.includeAlarmConnection) {
      gqlParams.includeAlarmConnection = criteria.filters.includeAlarmConnection;
    }
    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;
    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'DESC';
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page + 1;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    if (criteria.filters.minDate && criteria.filters.maxDate) {
      gqlParams.minDate = criteria.filters.minDate;
      gqlParams.maxDate = criteria.filters.maxDate;
    }

    if (criteria.filters.active) {
      gqlParams.active = criteria.filters.active;
    }

    return await this.graphqlService
      .query(this.getEquipmentActiveAlarmsQuery(), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const alarmsData = result.data.equipmentActiveAlarms.itemsPage;
          const alarmsResult: PagedResult<Alarm> = new PagedResult<Alarm>();
          if (Array.isArray(alarmsData)) {
            alarmsResult.itemsPage = alarmsData.map(alarmsDataItem => {
              return new Alarm(alarmsDataItem);
            });
          }
          alarmsResult.total = result.data.equipmentActiveAlarms.total;
          return alarmsResult;
        })
      )
      .toPromise();
  }

  public alarmExportCsv(minDate: string, maxDate: string, equipmentIds: string[]): Observable<any> {
    return this.httpClient.post(`${document.location.protocol}//${document.location.hostname}${environment.apiPort}/${this.languageService.currentLanguage}/alarms/export/csv`, {
      minDate,
      maxDate,
      equipmentIds
    }, {
      observe: 'response',
      responseType: 'blob' as 'json',
      headers: {
        Authorization: `Bearer ${this.tokenSessionService.getToken()}`
      }
    });
  }

  public async getEquipmentAlarmsHistory(equipmentId: string, criteria: AlarmsListSearchCriteria): Promise<PagedResult<Alarm>> {
    const allowedEquipmentsIds = [];
    allowedEquipmentsIds.push(equipmentId);
    const gqlParams: any = { allowedEquipmentsIds };
    if (criteria.filters.includeAlarmConnection) {
      gqlParams.includeAlarmConnection = criteria.filters.includeAlarmConnection;
    }

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    if (criteria.sort.direction) {
      gqlParams.sortDir = criteria.sort.direction;
    }
    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page + 1;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    if (criteria.filters.minDate && criteria.filters.maxDate) {
      gqlParams.minDate = criteria.filters.minDate;
      gqlParams.maxDate = criteria.filters.maxDate;
    }
    gqlParams.alarmDashboardOnlyActive = false;
    return await this.graphqlService
      .query(this.getEquipmentAlarmsHistoryQuery(), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const alarmsData = result.data.getAlarmsForEquipments.itemsPage;
          const alarmsResult: PagedResult<Alarm> = new PagedResult<Alarm>();
          if (Array.isArray(alarmsData)) {
            alarmsResult.itemsPage = alarmsData.map(alarmsDataItem => {
              return new Alarm(alarmsDataItem);
            });
          }
          alarmsResult.total = result.data.getAlarmsForEquipments.total;
          return alarmsResult;
        })
      )
      .toPromise();
  }


  public getFiles(criteria: any): Observable<any> {
    return this.graphqlService.query(this.getFilesQuery(), { criteria }, true).pipe(map(result => result.data.files));
  }

  public prepareForViewListChildDevicesOfMainDevice(equipment: Equipment): Device[] {
    let result: Device[] = [];

    const idEquipmentArray = equipment.id.split('-');
    if (!equipment.childDevicesOfMainDevice || !Array.isArray(equipment.childDevicesOfMainDevice)) {
      return equipment.childDevicesOfMainDevice;
    }

    const wantedOrder = [DeviceType.MEASURE, DeviceType.ENERGY, DeviceType.HPF, DeviceType.GPIO];

    for (const orderedDeviceType of wantedOrder) {
      const devicesByType: Device[] = equipment.childDevicesOfMainDevice.filter((device: Device) =>
        device.type === orderedDeviceType &&
        (!UtilsService.NotNullOrUndefined(device.monitored_unit_id) || (UtilsService.NotNullOrUndefined(idEquipmentArray[1])
            && device.monitored_unit_id === Number(idEquipmentArray[1]))
          || (UtilsService.NotNullOrUndefined(device.monitored_unit_id) && device.monitored_unit_id === 0))
      );

      result = result.concat(devicesByType);
    }

    return result;
  }

  public getEventsForEquipment(equipmentId: string, criteria: LogsListSearchCriteria): Promise<EventsResult> {
    const gqlParams: any = { equipmentId };

    if (Array.isArray(criteria.filters.types) && criteria.filters.types.length) {
      gqlParams.types = criteria.filters.types;
    }

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;
    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'DESC';
    }

    if (criteria.filters.minDate && criteria.filters.maxDate) {
      gqlParams.minDate = criteria.filters.minDate;
      gqlParams.maxDate = criteria.filters.maxDate;
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page + 1;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return this.graphqlService
      .query(this.getEventsForEquipmentQuery(), gqlParams, false)
      .pipe(
        map(result => {
          const eventsData = result.data.getEventsForEquipment.itemsPage;
          const eventsResult: EventsResult = {
            eventsPage: [],
            total: 0
          };
          if (Array.isArray(eventsData)) {
            eventsResult.eventsPage = eventsData.map(eventData => {
              return new Event(eventData);
            });
          }
          eventsResult.total = result.data.getEventsForEquipment.total;
          return eventsResult;
        })
      )
      .pipe(take(1))
      .toPromise() as Promise<EventsResult>;
  }

  public async getEventTypes(): Promise<EventTypesResult> {
    return this.graphqlService
      .query(this.getEventTypesQuery(), {}, false)
      .pipe(
        map(resultData => {
          const result: EventTypesResult = { eventTypesPage: [], total: 0 };
          const eventsData = resultData.data.eventTypes.items;
          if (Array.isArray(eventsData)) {
            result.eventTypesPage = eventsData.map(eventTypeData => {
              return new EventType(eventTypeData);
            });
          }
          result.total = resultData.data.eventTypes.meta.total;
          return result;
        })
      )
      .pipe(take(1))
      .toPromise() as Promise<EventTypesResult>;
  }

  public async getLastTimeLive(equipmentId: string): Promise<Date> {
    const queryRes: any = (await this.graphqlService
      .query(
        gql`
          query equipmentLastTimeLive($equipmentId: String!) {
            equipmentLastTimeLive(equipmentId: $equipmentId)
          }
        `,
        {
          equipmentId
        },
        false
      )
      .pipe(take(1))
      .toPromise()) as unknown as Promise<any>;

    let lastTimeLive: Date = null;
    console.log('EquipmentDetailsService getLastTimeLive queryRes', queryRes);
    if (queryRes && queryRes.data && queryRes.data.equipmentLastTimeLive) {
      lastTimeLive = queryRes.data.equipmentLastTimeLive;
      console.log('EquipmentDetailsService getLastTimeLive lastTimeLive 1', lastTimeLive);
      if (lastTimeLive) {
        lastTimeLive = this.dateService.moment(lastTimeLive).toDate();
        console.log('EquipmentDetailsService getLastTimeLive lastTimeLive 2', lastTimeLive);
      }
    }
    return lastTimeLive;
  }


  private getEventTypesQuery() {
    return gql`
      query getEventTypes {
        eventTypes(criteria: {
          pagination: { page: 1, perPage: 1000 }
          sort: { field: "name", direction: ASC }
        }) {
          meta {
            total
          }
          items {
            id
            _id
            color
            translations {
              locale
              fields {
                field
                value
              }
            }
          }
        }
      }
    `;
  }

  private getEventsForEquipmentQuery() {
    return gql`
      query getEventsForEquipment(
        $equipmentId: String!
        $types: [String!]
        $minDate: String
        $maxDate: String
        $sortField: String!
        $sortDir: String!
        $page: Float!
        $perPage: Float!
      ) {
        getEventsForEquipment(
          criteria: {
            equipmentId: $equipmentId
            types: $types
            minDate: $minDate
            maxDate: $maxDate
            sortField: $sortField
            sortDir: $sortDir
            page: $page
            perPage: $perPage
          }
        ) {
          total
          itemsPage {
            id
            date_time
            description
            event_id
            generated_by
            code
            extra_info {
              alarm_value
              alarm_limit
              acquitted_alarm
              parameter
              old_value
              new_value
            }
            event_type {
              id
              name
              color
              backgroundColor
              translations {
                locale
                fields {
                  field
                  value
                }
              }
            }
            user {
              _id
              email
              firstName
              lastName
            }
          }
        }
      }
    `;
  }

  private getEquipmentQuery(id: string): any {
    return gql`
      query getEquipmentById {
        getEquipmentById(id: "${id}") {
          _id
          id
          bottom_tap_height
          inactive
          status {
            id
            name
            color
            backgroundColor
            icon
            translations {
              locale
              fields {
                field
                value
              }
            }
          }
          unit_flags_and_alarms {
            average_reference
            simultaneous_unit_status
            progression
          }
          customerLocation {
            _id
            name
            installer {
              _id
              name
              phone
              email
              streetAndNumber
              postalCode
              city
              country
              chain {
                _id
                name
              }
              manager {
                _id
                email
                firstName
                lastName
                profileImage
              }
            }
            chain {
              _id
              name
            }
            phone
            email
            country
            postalCode
            city
            streetAndNumber
            latitude
            longitude
            manager {
              _id
              email
              firstName
              lastName
              profileImage
            }
          }
          referenceCentrale
          name
          fluid {
            id
            name
            gwp
          }
          lowLevel
          main_device_id {
            id
            parent_id {
              id
              type
              software_version
              hardware_version
              kernel_version
              serial_number
            }
            type
            software_version
            hardware_version
            serial_number
            kernel_version
            license_enabled

            mac_address_external
            connection_type
            wifi_ssid
            wifi_key
            wifi_type
            dhcp
            gateway
            mask
            ip
            vpn_ip_address
          }
          childDevicesOfMainDevice {
            id
            type
            software_version
            hardware_version
            kernel_version
            serial_number
            strain_gauge_id
            hp_min
            hp_max
            hp_calibration
            input_temp_correction_auto
            input_temp_correction
            output_temp_correction_auto
            output_temp_correction
            lp_calibration
            hp_gas_calibration
            lp_min
            lp_max
            hp_gas_min
            hp_gas_max
            lp_enabled
            hp_gas_enabled
            suction_temp_enabled
            discharge_temp_enabled
            suction_temp_correction_auto
            suction_temp_correction
            discharge_temp_correction_auto
            discharge_temp_correction
            is_configured
            replacement_date

            mac_address_external
            connection_type
            wifi_ssid
            wifi_key
            wifi_type
            dhcp
            gateway
            mask
            ip
            vpn_ip_address

            voltage_recording
            voltage_recalculation
            voltage_reference

            monitored_unit_id

            engines {
              assignement
              power_ti
              number_ti
              assignement_percent
            }

            engines_mapping {
              engine_id
              ti_mapping
            }
          }
          totalUnsolvedChangeRequests

          statistics_3_days_limit
          low_level_limit
          low_level_delay
          low_level_limit_pumpdown
          low_level_delay_pumpdown
          pumpdown_status
          fluid_charge
          fluid_type
          tank_type
          tank_tilt
          tank_number
          tank_volume
          tank_height
          tank_diameter
          sub_cooler
          condenser_volume
          hp_consign
          floating_hp
          floating_lp
          lp_consign
          compressor_type
          condenser_type
          condenser_power
          external_temp_correction_auto
          external_temp_correction
          installation_date
          column_type
          column_diameter
          column_height
          column_tare
          subcritical
          transcritical
          last_relearning_date
          lock
          last_tare_date
          tank_length
          quilting
          heat_recovery
          watering_condenser
          engines {
            id
            variator
            reference
            distribution
            name
            engine_mode
            engine_type
            stage
          }
        }
      }
    `;
  }

  private getEquipmentsInCustomerLocationQuery(skipTotal: boolean = false): any {
    return gql`
      query getEquipmentsInCustomerLocation($customerLocationIds: [String!],$search: String, $page: Float!, $perPage: Float!) {
        getEquipmentsInCustomerLocation(criteria: { customerLocationIds: $customerLocationIds,search:$search, page: $page, perPage: $perPage, sortField: "name", sortDir: "ASC", skipTotal:${skipTotal} }) {
          total
          itemsPage {
            _id
            id
            name
            inactive
            unit_flags_and_alarms {
              simultaneous_unit_status
            }
            customerLocation {
            _id
            }
          }
        }
      }
    `;
  }

  private deactivateAlarmsForEquipmentMutation(): any {
    return gql`
      mutation deactivateAlarmsForEquipment($equipmentId: String!) {
        deactivateAlarmsForEquipment(equipmentId: $equipmentId)
      }
    `;
  }

  public async getAlarm(id: string) {
    const gqlParams: any = {};
    gqlParams.id = id;
    return this.graphqlService
      .query(this.getAlarmQuery(), gqlParams, false)
      .pipe(
        map(result => {
          return result.data.getAlarm;
        })
      );
  }

  public getEventForAlarmModalQuery() {
    return gql`query getEventForAlarmModalQuery (
    $id: String!
    $equipmentId: String!
    ) {
      getEventForAlarmModalQuery(criteria: {
      id: $id
      equipmentId: $equipmentId
      }) {
          id
          extra_info {
          alarm_value
          alarm_limit
          acquitted_alarm
          parameter
          old_value
          new_value
          }
          date_time
      }
    }`;
  }

  public async getEventForAlarmModal(eventId: string, equipmentId: string) {
    const gqlParams: any = {};
    gqlParams.id = eventId;
    gqlParams.equipmentId = equipmentId;
    return this.graphqlService
      .query(this.getEventForAlarmModalQuery(), gqlParams, false)
      .pipe(
        map(result => {
          return result.data.getEventForAlarmModalQuery;
        })
      );
  }

  public getAlarmQuery() {
    return gql`query getAlarm ($id: String!) {
      getAlarm(id: $id) {
          id
          date_time
          evt_source_id
          alarm_type {
              id
              color
              icon
          }
          source_id{
          id
          }
          target_id {
          id
              name
              referenceCentrale
              fluid_charge
              fluid {
                gwp
              }
              customerLocation {
                  name
                  streetAndNumber
                  postalCode
                  city
                  country
              }
          }
      }
    }`;
  }

  private getEquipmentActiveAlarmsQuery(): any {
    return gql`
      query equipmentActiveAlarms(
        $equipmentId: String!
        $sortField: String!
        $sortDir: String!
        $page: Float!
        $perPage: Float!
        $minDate: String
        $maxDate: String
        $active: Boolean
      ) {
        equipmentActiveAlarms(
          criteria: {
            equipmentId: $equipmentId
            page: $page
            perPage: $perPage
            sortField: $sortField
            sortDir: $sortDir
            minDateIsoStr: $minDate
            maxDateIsoStr: $maxDate
            active: $active
          }
        ) {
          total
          itemsPage {
            id
            date_time
            value
            generated_by {
              id
              date_time
              description
              event_type {
                _id
                translations {
                  locale
                  fields {
                    field
                    value
                  }
                }
              }
            }
            acquittedOn
            acquittedBy_id {
              id
              date_time
              description
              event_type {
                _id
                translations {
                  locale
                  fields {
                    field
                    value
                  }
                }
              }
            }
            translations {
              locale
              fields {
                field
                value
              }
            }
            alarm_type {
              id
              color
              backgroundColor
              icon
              translations {
                locale
                fields {
                  field
                  value
                }
              }
            }
            source_id {
              id
              type
            }
            target_id {
              id
              referenceCentrale
              name
            }
            code_name
            evt_source_id
          }
        }
      }
    `;
  }

  private getEquipmentAlarmsHistoryQuery(): any {
    return gql`
      query getAlarmsForEquipments(
        $allowedEquipmentsIds: [String!]
        $page: Float!
        $perPage: Float!
        $minDate: String
        $maxDate: String
        $alarmDashboardOnlyActive: Boolean
        $includeAlarmConnection: Boolean
        $sortDir: String
        $sortField: String
      ) {
        getAlarmsForEquipments(
          criteria: {
            allowedEquipmentsIds: $allowedEquipmentsIds
            page: $page
            perPage: $perPage
            minDate: $minDate
            maxDate: $maxDate
            alarmDashboardOnlyActive: $alarmDashboardOnlyActive
            includeAlarmConnection:$includeAlarmConnection
            sortDir: $sortDir
            sortField: $sortField
          }
        ) {
          total
          itemsPage {
            id
            date_time
            value
            generated_by {
              id
              date_time
              description
              event_type {
                _id
                translations {
                  locale
                  fields {
                    field
                    value
                  }
                }
              }
            }
            acquittedOn
            acquittedBy_id {
              id
              date_time
              description
              event_type {
                _id
                translations {
                  locale
                  fields {
                    field
                    value
                  }
                }
              }
            }
            translations {
              locale
              fields {
                field
                value
              }
            }
            alarm_type {
              id
              color
              backgroundColor
              icon
              translations {
                locale
                fields {
                  field
                  value
                }
              }
            }
            source_id {
              id
              type
            }
            target_id {
              id
              referenceCentrale
              name
            }
            code_name
            evt_source_id
          }
        }
      }
    `;
  }


  private getFilesQuery(): any {
    return gql`
      query dniFiles($criteria: FilterCriteriaFiles!) {
        files(criteria: $criteria) {
          id
          fileName
          dateUpdated
          owner {
            firstName
            lastName
          }
        }
      }
    `;
  }
}
