<div class="autocomplete-container container-fluid">

  <mat-form-field [floatLabel]="floatLabelOption"         (click)='handleFocus()'  class='{{classListFormField}}' #searchMatFormField    >
    <mat-label >{{label |translate}}</mat-label>
    <input
      matInput
      #searchInput
      type="text"
      autocomplete='off'
      (focusout)='handleBlur()'
      [formControl]="searchControl"
      [placeholder]="placeholderDisplayText"
      (input)="openDropdown()"

    />


    <mat-spinner matSuffix (click)='toggleDropdown()'  diameter='18' *ngIf='isLoading' >spinner</mat-spinner>
    <div matSuffix  (click)='toggleDropdown()' *ngIf='!isLoading'><mat-icon  >keyboard_arrow_down</mat-icon></div>
  </mat-form-field>



  <div *ngIf="showDropdown "
       class="bg-white shadow-sm mat-primary overflow-hidden dropdown  {{classListDropdown}} "
       #dropdownContainer
       @dropdownAnimation >

    <div *ngIf='isLoading' class='mat-mdc-option mdc-list-item mat-mdc-option-multiple text-break'>Loading ...</div>
    <div   class="mat-mdc-option mdc-list-item mat-mdc-option-multiple text-break light-grey " *ngIf='(!filteredItems || filteredItems?.length==0 )&& !isLoading'>
      {{'website.multi_select_autocomplete.no_result_found'| translate}}
    </div>

      <cdk-virtual-scroll-viewport *ngIf='filteredItems?.length>0 && !isLoading'   [style.min-height.px]="getViewportHeight()"
                                   [style.max-height.px]="400"
                                   [itemSize]="20"
                                   [maxBufferPx]="20 * 10"
                                   [minBufferPx]="20 * 5" class='viewport-cdk-scroll' >

        <ng-container *ngIf='!bindOptGroup'>
          <div  *cdkVirtualFor="let option of filteredItems"   class="mat-mdc-option mdc-list-item mat-mdc-option-multiple text-break">      <mat-checkbox
            [checked]="isSelected(option)"
            (change)="toggleSelection(option);"
            [color]='"primary"'
          >
            {{ getOptionLabel(option) }}
          </mat-checkbox></div>
        </ng-container>
        <ng-container *ngIf='bindOptGroup'>
          <div     *cdkVirtualFor="let option of filteredItems" >
            <mat-optgroup  >{{getValue(option,bindOptGroup) | translate}}</mat-optgroup>
            <div class='mat-mdc-option mdc-list-item' *ngFor='let item of getValue(option,this.bindOptGroupItems)'  >
              <mat-checkbox
                [checked]="isSelected(item)"
                (change)="toggleSelection(item);"
                [color]='"primary"'
              >
                {{ getOptionLabel(item) }}
              </mat-checkbox>
            </div>

          </div>
        </ng-container>


      </cdk-virtual-scroll-viewport>


  </div>
</div>
