import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GraphqlService } from '../../../graphql/graphql.service';
import { TokenSessionService } from '../../../shared/services/token.session.service';
import { UserInfo } from '../../domain/services/user.interfaces';
import { MyUserUpdateInput } from '../models/my-user.update.input';
import { User } from '../models/user';
import { UserUpdateInput } from '../models/user.update.input';
import { Permission } from '../services/user.enums';
import { UserSearchCriteria } from './user-search-criteria.interface';
import * as MUTATIONS from './user.mutations';
import { BannerService } from '@shared/components/banner/banner.service';
import { BannerTypes } from '@shared/shared.enums';
import { UserNotification } from '@users/domain/models/user-notification';
import { ProfileService } from '@shared/services/profile.service';

export interface LoginStatusUpdate {
  userInfo: UserInfo;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public loginStatusUpdates: BehaviorSubject<LoginStatusUpdate> = new BehaviorSubject<LoginStatusUpdate>(null);
  public redirectUrl: string;

  constructor(
    private translateService: TranslateService,
    private graphqlService: GraphqlService,
    private tokenSessionService: TokenSessionService,
    private bannerService: BannerService,
    private profileService: ProfileService
  ) {}
  public async recover(email: string, code: string, password: string): Promise<boolean> {
    try {
      await this.graphqlService.mutate(MUTATIONS.recover, { email, code, password }).toPromise();
      const bannerMsg = this.translateService.instant(`BANNER.PASSWORD_RESET_SUCCESS`);
      this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, 3000);
      return true;
    } catch (error) {
      const bannerMsg = this.translateService.instant(`AUTH.ERRORS.PASSWORD_SHOULD_DIFFER`);
      this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, 10000);
      return false;
    }
  }
  public async askRecover(email: string, link: string, locale: string): Promise<boolean> {
    try {
      await this.graphqlService.mutate(MUTATIONS.askRecover, { email, link, locale }).toPromise();
      const bannerMsg = this.translateService.instant(`BANNER.RESET_CODE_SENT`, { email });
      this.bannerService.addMessage(bannerMsg, BannerTypes.SUCCESS, -1);
      return true;
    } catch (error) {
      const err = JSON.parse(JSON.stringify(error));
      console.log(err);
      let bannerMsg: string;
      bannerMsg = this.translateService.instant(err.networkError.error.errors[0].translationKey);
      this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, -1);
      return false;
    }

  }

  public async retrieveNotifications(): Promise<UserNotification[]> {
    try {
      return await this.graphqlService.query(this.retrieveNotificationsQuery(), {}).pipe(
        take(1),
        map(result => {
          this.bannerService.addNotification(result.data.retrieveNotifications);
          return result.data.retrieveNotifications;
        })
      )
        .toPromise();
    } catch (error) {
      return undefined;
    }
  }


  public async retrieveFeaturesFlag(): Promise<string[]> {
    try {
      return await this.graphqlService.query(this.retrieveFeatureFlagsQuery(), {}).pipe(
        take(1),
        map(result => {
          return result.data.retrieveFeaturesFlag;
        })
      )
        .toPromise();
    } catch (error) {
      return undefined;
    }
  }

  public async testEmail(email: string): Promise<boolean> {
    try {
      const data = (await this.graphqlService.mutate(MUTATIONS.emailTest, { email }).toPromise()) as any;
      return true;
    } catch (error) {
      return false;
    }
  }
  public async login(email: string, password: string, rememberMe: boolean): Promise<boolean> {
    try {
      const data = (await this.graphqlService.mutate(MUTATIONS.login, { email, password }).toPromise()) as any;
      this.tokenSessionService.storeToken(data.login, rememberMe);
      this.loginStatusUpdates.next({
        userInfo: this.tokenSessionService.userInfo,
        token: this.tokenSessionService.getToken()
      });
      return true;
    } catch (error) {
      if (!email || !password) {
        console.error('UserService login Error: No email or password');
        const bannerMsg = this.translateService.instant(`BANNER.ALL_FIELDS_ARE_REQUIRED`);
        this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, -1);
      } else {
        const err = JSON.parse(JSON.stringify(error));
        let bannerMsg: string;
        if (err.networkError.error.errors[0].errorCode === '401') {
          bannerMsg = this.translateService.instant(err.networkError.error.errors[0].translationKey);
        } else {
          bannerMsg = this.translateService.instant(`BANNER.INVALID_EMAIL_OR_PASSWORD`);
        }
        this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, -1);
        console.error('UserService login Error:', bannerMsg);
      }

      return false;
    }
  }

  public async adminAsUser(email: string): Promise<boolean> {
    try {
      const data = (await this.graphqlService.mutate(MUTATIONS.adminLoginAsUser, { email }).toPromise()) as any;

      this.tokenSessionService.storeToken(data.adminLoginAsUser, true);
      this.loginStatusUpdates.next({
        userInfo: this.tokenSessionService.userInfo,
        token: this.tokenSessionService.getToken()
      });
      return true;
    } catch (error) {
      if (!email ) {
        console.error('UserService login Error: No email or password');
        const bannerMsg = this.translateService.instant(`BANNER.ALL_FIELDS_ARE_REQUIRED`);
        this.bannerService.addMessage(bannerMsg, BannerTypes.ERROR, -1);
      }
      return false;
    }
  }
  public async updateToken(input: UserUpdateInput): Promise<void> {
    const data = (await this.graphqlService.mutate(MUTATIONS.updateToken, { input }).toPromise()) as any;
    this.tokenSessionService.resetToken(data.updateToken);
  }

  public async updateMyToken(input: MyUserUpdateInput): Promise<void> {
    const data = (await this.graphqlService.mutate(MUTATIONS.updateMyToken, { input }).toPromise()) as any;
    this.tokenSessionService.resetToken(data.updateMyToken);
  }

  public async resendCode(email: string, returnLink: string): Promise<void> {
    await this.graphqlService.mutate(MUTATIONS.resendCode, { email, returnLink }).toPromise();
  }

  public async findByRole(roleId: string): Promise<User[]> {
    const users = await this.graphqlService.query(this.queryUsersByRole, { roleId }).toPromise();
    return (users as any).users.items;
  }

  public loggedInUserHasPermissions(permissions: Permission[]): boolean {
    if (!this.tokenSessionService.isAuthenticated()) {
      return false;
    }

    return this.tokenSessionService.tokenIncludesPermissions(this.tokenSessionService.getToken(), permissions);
  }

  private get queryUsersByRole(): any {
    return gql`
      query users($roleId: String!) {
        users(criteria: { filters: { role: { value: $roleId, operation: EQ } } }) {
          items {
            ...fields
          }
        }
      }
      ${MUTATIONS.fields}
    `;
  }

  public getUsers(criteria: UserSearchCriteria): Promise<User[]> {
    const gqlParams: any = {};

    if (criteria.sort.field) {
      gqlParams.sortField = criteria.sort.field;
    }
    gqlParams.sortDir = criteria.sort.direction;

    if (!gqlParams.sortDir) {
      gqlParams.sortDir = 'ASC';
    }

    if (criteria.pagination) {
      gqlParams.page = criteria.pagination.page;
      gqlParams.perPage = criteria.pagination.perPage;
    }

    return this.graphqlService
      .query(this.getUsersQuery(), gqlParams, false)
      .pipe(
        take(1),
        map(result => {
          const usersData = result.data.users.items;
          return usersData;
        })
      )
      .toPromise() as Promise<User[]>;
  }


  public getMyUser(): Promise<User> {
    return this.graphqlService
      .query(this.retrieveMyUser(), false)
      .pipe(
        take(1),
        map(result => {
          return result.data.retrieveMyUser;
        })
      )
      .toPromise() as Promise<User>;
  }


  private retrieveNotificationsQuery() {
    return gql`
      query retrieveNotifications{
        retrieveNotifications {
        title
        summary
        content
        criticality
        idEquipment
        idSubscription
        broadcastDate
        expirationDate
        expectedActions
        ruleId
        type
        notificationRuleLang {
          trad {
            title
            summary
            content
            lang
          }
          lang
        }
        }
      }
    `;
  }

  private retrieveFeatureFlagsQuery() {
    return gql`
      query retrieveFeaturesFlag{
        retrieveFeaturesFlag{
          _id
          active
          featureName
        }
      }
    `;
  }

  private retrieveMyUser() {
    return gql`
      query retrieveMyUser{
        retrieveMyUser{
          _id
          preference{
          unit
        }
        }
      }
    `;
  }

  private getUsersQuery() {
    return gql`
      query getUsers($sortField: String!, $sortDir: SortDirection!, $page: Float!, $perPage: Float!) {
        users(criteria: { sort: { field: $sortField, direction: $sortDir }, pagination: { page: $page, perPage: $perPage } }) {
          meta {
            total
          }
          items {
            _id
            firstName
            lastName
          }
        }
      }
    `;
  }

  public getMyToken() {
    return this.graphqlService
      .query(this.retrieveMyToken())
      .pipe(
        map(result => {
          const token = result.data.retrieveMyToken;
          return token;
        })
      );
  }

  private retrieveMyToken() {
    return gql`
      query  retrieveMyToken {
        retrieveMyToken
      }
    `;
  }

  public getUserToken(id: number) {
    return this.graphqlService
      .query(this.retrieveUserToken(id), { id })
      .pipe(
        map(result => {
          const token = result.data.retrieveUserToken;
          return token;
        })
      );
  }

  private retrieveUserToken(id: number) {
    return gql`
      query  retrieveUserToken($id: Float!) {
        retrieveUserToken(id: $id)
      }
    `;
  }

  public getDniLicense(dni: string) {
    const gqlParams: any = {};
    gqlParams.dni = dni;
    return this.graphqlService
    .query(this.retrieveLicense(), gqlParams, false)
    .pipe(
      map (result => {
        return result.data.retrieveLicense;
      })
    );
  }

  private retrieveLicense() {
    return gql`
      query  retrieveLicense($dni: String!) {
        retrieveLicense(dni: $dni)
      }
    `;
  }


}
