import 'hammerjs';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule, SecurityContext } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Config } from './config';
import { FooterComponent } from '@core/footer/footer.component';

import { SharedModule } from '@shared/shared.module';
import { GUARDS } from '@shared/guards';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AuthTokenDialogComponent } from '@core/authTokenDialog/authTokenDialog.component';
import { LicenceDialogComponent } from '@core/licence-dialog/licence-dialog.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorCustomClass } from '@shared/helpers/mat-paginator-custom-class';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { DashboardLayoutModule } from './layouts/dashboard-layout/dashboard-layout.module';
import { GraphQLModule } from '@graphql/graphql.module';

import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter } from '@shared/services/custom-date-adapter.service';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false
  };
};


@NgModule({ declarations: [
        AppComponent,
        FooterComponent,
        AuthTokenDialogComponent,
        LicenceDialogComponent
    ],
    bootstrap: [AppComponent], imports: [SharedModule,
        NgSelectModule,
        BrowserModule,
        FormsModule,
        NgxMaskModule.forRoot(maskConfigFunction),
        TranslateModule.forRoot({ defaultLanguage: Config.defaultLanguage }),
        AppRoutingModule,
        BrowserAnimationsModule,
        DashboardLayoutModule,
        GraphQLModule,
        MatExpansionModule,
        MatSnackBarModule], providers: [GUARDS,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
                verticalPosition: 'bottom', // 'top' | 'bottom'
                horizontalPosition: 'end'
            }
        },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LOCALE_ID, useValue: 'fr' },
        {
            provide: MatPaginatorIntl,
            useFactory: (translate: any) => {
                return new MatPaginatorCustomClass(translate);
            },
            deps: [TranslateService]
        }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {
  constructor() {
    registerLocaleData(localeFr, 'fr');
  }
}
