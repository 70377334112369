import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { AppInjector } from './app/app.injector';

if (environment.production) {


  window.console.log = () => {
  };
  window.console.debug = () => {
  };
  window.console.info = () => {
  };
}
enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(moduleRef => {
    AppInjector.setInjector(moduleRef.injector);
  })
  .catch(err => console.error('Error in main.ts: ', err));
