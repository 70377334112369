
<mat-toolbar  color="primary" class="toolbar">
  <button mat-icon-button  class="d-md-none" (click)="toggleMenu()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="d-none d-sm-block">  <a href="/" name="PolarVisor homepage">
        <img src="../../../../../assets/images/polarvisor-logo.svg" alt="PolarVisor Homepage" title="PolarVisor Homepage" />
      </a></span>
  <!-- Add additional toolbar items here -->
  <span class="spacer"></span>
  <div class="mt-1 me-3">
    <app-language-switcher></app-language-switcher>
  </div>

  <button mat-icon-button (click)="logout()">
    <mat-icon class="custom-icon">logout</mat-icon>
  </button>

  <span (click)="goToDashboard()" class="me-2 mt-1 cursor-pointer"><div  class="user-icon inverted">{{this.nameInitial}}</div></span>

  <span (click)="goToDashboard()" class="user-name cursor-pointer ">{{ userInfo?.name }}<br> {{ (userInfo?.roles)?.[0] }}</span>
</mat-toolbar>

<div *ngIf="!isMobile" class="sidebar-toggle d-none d-md-block d-sm-block cursor-pointer {{isCollapsed?'expand-toggle':null}}">
  <a  (click)="toggleMenu()">
    <mat-icon *ngIf="isCollapsed">navigate_next</mat-icon>
    <mat-icon *ngIf="!isCollapsed">navigate_before</mat-icon>
  </a>
</div>
<mat-sidenav-container autosize="true" class="sidenav-container remove-scroll">

    <mat-sidenav
      [disableClose]="true"
      [ngClass]="!isCollapsed ? 'expanded' : ''"
      [mode]="isMobile ? 'over' : 'side'"
      [opened]="!isMobile"


      >

      <mat-nav-list class="p-0 nav-list" (selectionchange)="this.reloadCounterAlarms()">


        <a mat-list-item  class="h-100" (click)="goToDashboard()"  >
<div class="nav-item">
  <span ><div  class="user-icon">{{this.nameInitial}}</div></span>
  <span  *ngIf="!isCollapsed" ><span class="fw-medium">{{ userInfo?.name }}</span><br><span class="text-break light-grey">{{ (userInfo?.roles)?.[0] }}</span></span>
</div>
        </a>


        <a mat-list-item class="entry entry-token"  (click)="toggleForToken()"     matTooltipPosition="right"  matTooltip="{{isCollapsed ? 'ID PolarBox':null}}">

            <mat-icon  *ngIf="isCollapsed"  class='custom-icon-material  material-icons-outlined {{!isCollapsed?"adjust-icon-expand":"adjust-icon-collapse"}}' >vpn_key</mat-icon>



          <mat-form-field
            [floatLabel]="'always'"
            class="pv-sm-field "
            *ngIf="!isCollapsed"
            appearance="fill"
            style="width:100%;height:60px;margin:0rem;"
            subscriptSizing="dynamic">
            <mat-label>ID PolarBox</mat-label>
            <input matInput
                   class="cursor-pointer"
                   [type]="hide ? 'password' : 'text'"
                   [(ngModel)]="token"
                   readonly>
            <button
              mat-icon-button
              matSuffix
              class="mt-1"
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </a>
        <ng-container *ngFor='let section of itemsSideBar;let isFirst=first' class="test" >
        <ng-container *ngFor='let item of section.items;let isFirstItem=first'>
          <a

            mat-list-item
            *ngIf='!item.hide && !item.isExternalLink'
            class='h-100 entry '
            [ngClass]="{ 'pv-border-top': isFirst && isFirstItem }"
            [attr.aria-label]="item.ariaLabel"
            [routerLink]="item.link ?? null"
            (click)="isMobile?this.toggleMenu():null;item.onClick?.()"
            matTooltip="{{isCollapsed ? (item.title | translate) : null}}"
            matTooltipPosition="right"
            routerLinkActive='active-side-menu-link'
            [disabled]='item.disabled'
            [matx-if-has-permission]='item.hasPermissions ? item.hasPermissions : []'>
            <div class="text-break">
              <mat-icon *ngIf="item.isMaterialIcon" class='custom-icon-material material-icons-outlined'>{{item.icon}}</mat-icon>
              <span *ngIf="!item.isMaterialIcon" class=' {{item.icon}} custom-icon'></span>
              <span *ngIf="!isCollapsed" class="description d-inline  {{item.isMaterialIcon?'description-material':null}}">{{ item.title | translate }}
            </span>
              <div class="d-inline"  *ngIf='item.title==="website.sidebar.equipment"  &&  !isCollapsed'> <span
                                                                                                                  *ngIf='totalEquipmentsHavingAlarms?.totalEquipmentsWithActiveAlarms>0'
                                                                                                                  matTooltip="{{ 'website.equipment.alarm.red_badge' | translate }}"
                                                                                                                  class='custom-badge-danger badge-sm  '>{{ totalEquipmentsHavingAlarms?.totalEquipmentsWithActiveAlarms }}</span>
                <span
                       matTooltip="{{ 'website.equipment.alarm.yellow_badge' | translate }}"
                       *ngIf='totalEquipmentsHavingAlarms?.totalEquipmentsWithConnectionAlarm>0'
                       class='custom-badge-warn badge-sm'>{{ totalEquipmentsHavingAlarms?.totalEquipmentsWithConnectionAlarm }} </span></div>
              <div   *ngIf='item.title==="website.sidebar.equipment" && isCollapsed' >
                <mat-icon class="alarm-circle" *ngIf="totalEquipmentsHavingAlarms && totalEquipmentsHavingAlarms?.totalEquipmentsWithActiveAlarms!==0" >circle</mat-icon>
                <mat-icon *ngIf="totalEquipmentsHavingAlarms && totalEquipmentsHavingAlarms?.totalEquipmentsWithConnectionAlarm!==0" class="disconnection-circle" >circle</mat-icon>
              </div>
            </div>


          </a>
          <a
            mat-list-item
            *ngIf='!item.hide && item.isExternalLink'
            class='h-100 entry'
            [attr.aria-label]="item.ariaLabel"
            [href]="item.link ?? null"
            [disabled]='item.disabled'
            target="_blank"
            matTooltipPosition="right"
            matTooltip="{{isCollapsed ? (item.title | translate) : null}}"
            [matx-if-has-permission]='item.hasPermissions ? item.hasPermissions : []'>
            <div class="text-break">
            <mat-icon *ngIf="item.isMaterialIcon" class='custom-icon-material material-icons-outlined'>{{item.icon}}</mat-icon>
            <span *ngIf="!item.isMaterialIcon" class=' {{item.icon}} custom-icon'></span>
            <span *ngIf="!isCollapsed" class="description  {{item.isMaterialIcon?'description-material':null}}">{{ item.title | translate }}</span>
            </div>
          </a>

        </ng-container>
          <div  class="pv-border-bottom" *ngIf="section.hasToDisplayAfterPermissionFilter"></div>
        </ng-container>
      </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content class="main-content " style="scroll-padding-top: 200px;" cdkScrollable>

      <div class="content-wrapper-sidebar mb-3">
        <router-outlet></router-outlet>
      </div>
      <mat-divider></mat-divider>
      <p class='copyright'>&copy; {{ 'website.sidebar.copyright' | translate }}</p>

    </mat-sidenav-content>
  </mat-sidenav-container>



