import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule, SecurityContext } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
// import { UserService } from '../users/domain/services/user.service';
import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';
import { TokenSessionService } from './services/token.session.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  NotificationRulesDialogComponent
} from '@shared/components/banner/notification-rules-dialog/notification-rules-dialog.component';

import {
  CustomerLocationEquipmentsPopupMapComponent
} from './components/customer-location-equipments-popup-map/customer-location-equipments-popup-map.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { OverlayModule } from '@angular/cdk/overlay';
import { MarkdownModule } from 'ngx-markdown';


import { CustomRangePanelComponent } from '@shared/components/datepicker-range-preset/datepicker-preset-header/custom-range-panel/custom-range-panel.component';
import { UnitConvertPipe } from './pipes/unit-convert.pipe';
import { ConvertUnitLabelPipe } from './pipes/convert-unit-label.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { CustomDateAdapter } from '@shared/services/custom-date-adapter.service';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';


@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES,  NotificationRulesDialogComponent, CustomerLocationEquipmentsPopupMapComponent, CustomRangePanelComponent, LocalDatePipe, UnitConvertPipe, ConvertUnitLabelPipe],
  providers: [
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    //     return {
    //       link: httpLink.create({ uri: environment.apiUrl }),
    //       cache: new InMemoryCache(),
    //     };
    //   },
    //   deps: [HttpLink],
    // },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR'
    },
    TokenSessionService
    // UserService
  ],
  imports: [
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatListModule,
    MatBadgeModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTabsModule,
    MatBadgeModule,
    MatExpansionModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSortModule,
    MatTooltipModule,
    MatSliderModule,
    RouterModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    HighchartsChartModule,
    NgxSliderModule,
    NgSelectModule,
    MatRadioModule,
    TranslateModule,
    MarkdownModule.forRoot({sanitize: SecurityContext.NONE }),
    CdkVirtualScrollViewport,
    OverlayModule,
    MatRippleModule
  ],
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    CommonModule,
    MatListModule,
    MatBadgeModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTabsModule,
    MatBadgeModule,
    MatExpansionModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatSortModule,
    MatSliderModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatChipsModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    HighchartsChartModule,
    NgxSliderModule,
    ...COMPONENTS,
    NotificationRulesDialogComponent,
    ...DIRECTIVES,
    CustomerLocationEquipmentsPopupMapComponent,
    LocalDatePipe,
    UnitConvertPipe,
    ConvertUnitLabelPipe
  ]
})
export class SharedModule {}
